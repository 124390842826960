import axios from 'axios';

export const client = axios.create({
    baseURL: "https://distinct-ray-96.hasura.app/api/rest/resep",
    headers: {
        "x-hasura-admin-secret" : "2rdeC9vin6nK3XOhDwM6HJpjyK4mcXkBAQdzPsdxl9eMwrlLKk88p3Q3BSJwW5CU"
    }
});

export const getReseps = async () => {
    const response = await client.get("/")
        return response.data.misterk_resep;
};
